<template>
  <div class="group">
    <div class="top_one">
      <img v-if="groupInfo.avatar !== null" src="" alt="" />
      <img
        v-else
        width="40px"
        height="40px"
        src="@/assets/imgs/mrtx.png"
        alt=""
      />
      <div>
        <div>{{ groupInfo.name.slice(0, 14) }}...</div>
      </div>
      <el-popover
        placement="bottom"
        width="120"
        trigger="click"
        popper-class="group-page-popover"
      >
        <div class="group-detail-popover-box">
          <div class="more-item" @click="markFun">
            <img :src="require('@/assets/imgs/mark.png')" alt="" />
            <span>打标记</span>
          </div>

          <div class="more-item" @click="exportFileForGroup(groupInfo.id)">
            <img :src="require('@/assets/imgs/down.png')" alt="" />
            <span>导 出</span>
          </div>
        </div>
        <img
          width="25px"
          height="25px"
          slot="reference"
          src="../../../assets/imgs/more.png"
          alt=""
        />
      </el-popover>
    </div>
    <div class="top_two">
      <el-descriptions
        :column="1"
        :labelStyle="{
          width: '60px',
          textAlign: 'right',
          color: '#060606'
        }"
      >
        <el-descriptions-item label="ID">
          {{ groupInfo.id }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ groupInfo.create_time }}
        </el-descriptions-item>
        <el-descriptions-item label="电话">
          {{ groupInfo.phone }}
        </el-descriptions-item>

        <el-descriptions-item label="标记">
          <div>
            <el-tag v-for="item in tagList" :key="item.id" size="mini">
              {{ item.tag.tag }}
            </el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <!-- 标签弹框 -->
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <LabelForm @reciverLabel="reciverLabel" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getUserTagList, addTagForUser } from '@/api/tagged'
import LabelForm from '@/components/LabelForm.vue'
export default {
  name: 'UserCard',
  props: {
    groupInfo: Object
  },
  components: {
    LabelForm
  },
  data() {
    return {
      tagList: [],
      dialogVisible: false
    }
  },
  created() {},
  async mounted() {
    // 请求用户标记

    await this.getTagList()
  },
  methods: {
    // 用户
    async getTagList() {
      const data = {
        user: this.groupInfo.id
      }
      this.tagList = []

      const res = await getUserTagList(data)

      this.tagList = res.items
    },
    // 导出群组数据
    exportFileForGroup(id) {
      const herf =
        process.env.VUE_APP_BASE_API + `/whatsapp/users/${id}:export_excel`
      window.open(herf)
    },
    // 用户打标签开始
    async sendResult(val) {
      const data = {
        ids: val.tags
      }

      await addTagForUser(this.groupInfo.id, data)
      this.dialogVisible = val.flag
      this.getTagList()
    },
    // 收到选中的标签
    reciverLabel(val) {
      // 收到值发送请求
      if (val.tags.length !== 0) {
        this.sendResult(val)
      } else {
        // 否则关闭弹框
        this.dialogVisible = val.flag
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 打标记
    markFun() {
      this.dialogVisible = true
    }

    // 用户打标签结束
  },
  beforeDestroy() {
    this.tagList = []
  }
}
</script>

<style lang="scss" scoped>
.group {
  width: 100%;
  .top_one {
    display: flex;
    justify-content: space-between;
  }
  .top_two {
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #1a1a1b;
  }
}
</style>
<style lang="scss">
.group-detail-popover-box {
  .more-item {
    text-align: left;
    margin: 10px 0;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin-left: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #222222;
      &:hover {
        color: blue;
      }
    }
  }
}
.group-page-popover {
  width: 120px !important;
  min-width: 50px !important;
  padding: 5px;
  .user-item {
    user-select: none;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
    &:hover {
      color: #0084ff;
    }
  }
}
</style>
